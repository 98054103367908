// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-activewear-2-js": () => import("./../../../src/pages/activewear-2.js" /* webpackChunkName: "component---src-pages-activewear-2-js" */),
  "component---src-pages-africa-js": () => import("./../../../src/pages/africa.js" /* webpackChunkName: "component---src-pages-africa-js" */),
  "component---src-pages-alipay-error-js": () => import("./../../../src/pages/alipay-error.js" /* webpackChunkName: "component---src-pages-alipay-error-js" */),
  "component---src-pages-apparel-manufacturers-js": () => import("./../../../src/pages/apparel-manufacturers.js" /* webpackChunkName: "component---src-pages-apparel-manufacturers-js" */),
  "component---src-pages-asia-js": () => import("./../../../src/pages/asia.js" /* webpackChunkName: "component---src-pages-asia-js" */),
  "component---src-pages-bangladesh-js": () => import("./../../../src/pages/bangladesh.js" /* webpackChunkName: "component---src-pages-bangladesh-js" */),
  "component---src-pages-baycity-x-foursource-js": () => import("./../../../src/pages/baycity-x-foursource.js" /* webpackChunkName: "component---src-pages-baycity-x-foursource-js" */),
  "component---src-pages-branches-portugal-js": () => import("./../../../src/pages/branches/portugal.js" /* webpackChunkName: "component---src-pages-branches-portugal-js" */),
  "component---src-pages-branches-turkey-js": () => import("./../../../src/pages/branches/turkey.js" /* webpackChunkName: "component---src-pages-branches-turkey-js" */),
  "component---src-pages-buyers-js": () => import("./../../../src/pages/buyers.js" /* webpackChunkName: "component---src-pages-buyers-js" */),
  "component---src-pages-case-study-araujoirmaos-js": () => import("./../../../src/pages/case-study/araujoirmaos.js" /* webpackChunkName: "component---src-pages-case-study-araujoirmaos-js" */),
  "component---src-pages-case-study-asbx-js": () => import("./../../../src/pages/case-study/asbx.js" /* webpackChunkName: "component---src-pages-case-study-asbx-js" */),
  "component---src-pages-case-study-bl-design-js": () => import("./../../../src/pages/case-study/bl-design.js" /* webpackChunkName: "component---src-pages-case-study-bl-design-js" */),
  "component---src-pages-case-study-campro-sports-js": () => import("./../../../src/pages/case-study/campro-sports.js" /* webpackChunkName: "component---src-pages-case-study-campro-sports-js" */),
  "component---src-pages-case-study-confi-fashion-service-js": () => import("./../../../src/pages/case-study/confi-fashion-service.js" /* webpackChunkName: "component---src-pages-case-study-confi-fashion-service-js" */),
  "component---src-pages-case-study-cute-dress-industry-js": () => import("./../../../src/pages/case-study/cute-dress-industry.js" /* webpackChunkName: "component---src-pages-case-study-cute-dress-industry-js" */),
  "component---src-pages-case-study-fateks-kumas-js": () => import("./../../../src/pages/case-study/fateks-kumas.js" /* webpackChunkName: "component---src-pages-case-study-fateks-kumas-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-case-study-limton-corporation-js": () => import("./../../../src/pages/case-study/limton-corporation.js" /* webpackChunkName: "component---src-pages-case-study-limton-corporation-js" */),
  "component---src-pages-case-study-nexgen-sportswear-js": () => import("./../../../src/pages/case-study/nexgen-sportswear.js" /* webpackChunkName: "component---src-pages-case-study-nexgen-sportswear-js" */),
  "component---src-pages-case-study-seyfeli-js": () => import("./../../../src/pages/case-study/seyfeli.js" /* webpackChunkName: "component---src-pages-case-study-seyfeli-js" */),
  "component---src-pages-case-study-stuff-limited-js": () => import("./../../../src/pages/case-study/stuff-limited.js" /* webpackChunkName: "component---src-pages-case-study-stuff-limited-js" */),
  "component---src-pages-case-study-yellow-river-js": () => import("./../../../src/pages/case-study/yellow-river.js" /* webpackChunkName: "component---src-pages-case-study-yellow-river-js" */),
  "component---src-pages-certification-js": () => import("./../../../src/pages/certification.js" /* webpackChunkName: "component---src-pages-certification-js" */),
  "component---src-pages-china-js": () => import("./../../../src/pages/china.js" /* webpackChunkName: "component---src-pages-china-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-contact-us-contact-js": () => import("./../../../src/pages/contact-us/contact.js" /* webpackChunkName: "component---src-pages-contact-us-contact-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-delogue-x-foursource-js": () => import("./../../../src/pages/delogue-x-foursource.js" /* webpackChunkName: "component---src-pages-delogue-x-foursource-js" */),
  "component---src-pages-digital-textrends-hub-js": () => import("./../../../src/pages/digital-textrends-hub.js" /* webpackChunkName: "component---src-pages-digital-textrends-hub-js" */),
  "component---src-pages-dress-js": () => import("./../../../src/pages/dress.js" /* webpackChunkName: "component---src-pages-dress-js" */),
  "component---src-pages-eastern-europe-js": () => import("./../../../src/pages/eastern-europe.js" /* webpackChunkName: "component---src-pages-eastern-europe-js" */),
  "component---src-pages-europe-js": () => import("./../../../src/pages/europe.js" /* webpackChunkName: "component---src-pages-europe-js" */),
  "component---src-pages-explore-network-js": () => import("./../../../src/pages/explore-network.js" /* webpackChunkName: "component---src-pages-explore-network-js" */),
  "component---src-pages-fabric-suppliers-js": () => import("./../../../src/pages/fabric-suppliers.js" /* webpackChunkName: "component---src-pages-fabric-suppliers-js" */),
  "component---src-pages-face-masks-and-medical-wear-js": () => import("./../../../src/pages/face-masks-and-medical-wear.js" /* webpackChunkName: "component---src-pages-face-masks-and-medical-wear-js" */),
  "component---src-pages-for-suppliers-js": () => import("./../../../src/pages/for-suppliers.js" /* webpackChunkName: "component---src-pages-for-suppliers-js" */),
  "component---src-pages-garment-manufacturers-from-peru-js": () => import("./../../../src/pages/garment-manufacturers-from-peru.js" /* webpackChunkName: "component---src-pages-garment-manufacturers-from-peru-js" */),
  "component---src-pages-general-fabrics-js": () => import("./../../../src/pages/general-fabrics.js" /* webpackChunkName: "component---src-pages-general-fabrics-js" */),
  "component---src-pages-general-page-js": () => import("./../../../src/pages/general-page.js" /* webpackChunkName: "component---src-pages-general-page-js" */),
  "component---src-pages-gloves-js": () => import("./../../../src/pages/gloves.js" /* webpackChunkName: "component---src-pages-gloves-js" */),
  "component---src-pages-how-to-get-started-js": () => import("./../../../src/pages/how-to-get-started.js" /* webpackChunkName: "component---src-pages-how-to-get-started-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-india-sri-lanka-js": () => import("./../../../src/pages/india-sri-lanka.js" /* webpackChunkName: "component---src-pages-india-sri-lanka-js" */),
  "component---src-pages-ispo-textrends-award-brochure-summer-2022-js": () => import("./../../../src/pages/ispo-textrends-award/brochure-summer-2022.js" /* webpackChunkName: "component---src-pages-ispo-textrends-award-brochure-summer-2022-js" */),
  "component---src-pages-ispo-textrends-award-brochure-winter-2022-js": () => import("./../../../src/pages/ispo-textrends-award/brochure-winter-2022.js" /* webpackChunkName: "component---src-pages-ispo-textrends-award-brochure-winter-2022-js" */),
  "component---src-pages-ispo-textrends-award-js": () => import("./../../../src/pages/ispo-textrends-award.js" /* webpackChunkName: "component---src-pages-ispo-textrends-award-js" */),
  "component---src-pages-ispo-textrends-award-terms-and-conditions-js": () => import("./../../../src/pages/ispo-textrends-award/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-ispo-textrends-award-terms-and-conditions-js" */),
  "component---src-pages-jacket-js": () => import("./../../../src/pages/jacket.js" /* webpackChunkName: "component---src-pages-jacket-js" */),
  "component---src-pages-jeans-denim-2-js": () => import("./../../../src/pages/jeans-denim-2.js" /* webpackChunkName: "component---src-pages-jeans-denim-2-js" */),
  "component---src-pages-knitwear-js": () => import("./../../../src/pages/knitwear.js" /* webpackChunkName: "component---src-pages-knitwear-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-mango-x-foursource-js": () => import("./../../../src/pages/mango-x-foursource.js" /* webpackChunkName: "component---src-pages-mango-x-foursource-js" */),
  "component---src-pages-norte-2020-js": () => import("./../../../src/pages/norte-2020.js" /* webpackChunkName: "component---src-pages-norte-2020-js" */),
  "component---src-pages-pakistan-js": () => import("./../../../src/pages/pakistan.js" /* webpackChunkName: "component---src-pages-pakistan-js" */),
  "component---src-pages-payment-feedback-js": () => import("./../../../src/pages/payment-feedback.js" /* webpackChunkName: "component---src-pages-payment-feedback-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-members-js": () => import("./../../../src/pages/privacy-policy-members.js" /* webpackChunkName: "component---src-pages-privacy-policy-members-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-scheduler-deny-js": () => import("./../../../src/pages/scheduler-deny.js" /* webpackChunkName: "component---src-pages-scheduler-deny-js" */),
  "component---src-pages-scheduler-js": () => import("./../../../src/pages/scheduler.js" /* webpackChunkName: "component---src-pages-scheduler-js" */),
  "component---src-pages-shirt-js": () => import("./../../../src/pages/shirt.js" /* webpackChunkName: "component---src-pages-shirt-js" */),
  "component---src-pages-signup-buyer-company-js": () => import("./../../../src/pages/signup/buyer/company.js" /* webpackChunkName: "component---src-pages-signup-buyer-company-js" */),
  "component---src-pages-signup-buyer-individual-js": () => import("./../../../src/pages/signup/buyer/individual.js" /* webpackChunkName: "component---src-pages-signup-buyer-individual-js" */),
  "component---src-pages-signup-buyer-js": () => import("./../../../src/pages/signup/buyer.js" /* webpackChunkName: "component---src-pages-signup-buyer-js" */),
  "component---src-pages-signup-fabric-manufacturer-js": () => import("./../../../src/pages/signup/fabric-manufacturer.js" /* webpackChunkName: "component---src-pages-signup-fabric-manufacturer-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-signup-manufacturers-basic-js": () => import("./../../../src/pages/signup/manufacturers/basic.js" /* webpackChunkName: "component---src-pages-signup-manufacturers-basic-js" */),
  "component---src-pages-signup-manufacturers-enterprise-js": () => import("./../../../src/pages/signup/manufacturers/enterprise.js" /* webpackChunkName: "component---src-pages-signup-manufacturers-enterprise-js" */),
  "component---src-pages-signup-manufacturers-js": () => import("./../../../src/pages/signup/manufacturers.js" /* webpackChunkName: "component---src-pages-signup-manufacturers-js" */),
  "component---src-pages-signup-manufacturers-professional-js": () => import("./../../../src/pages/signup/manufacturers/professional.js" /* webpackChunkName: "component---src-pages-signup-manufacturers-professional-js" */),
  "component---src-pages-signup-thank-you-js": () => import("./../../../src/pages/signup/thank-you.js" /* webpackChunkName: "component---src-pages-signup-thank-you-js" */),
  "component---src-pages-socks-js": () => import("./../../../src/pages/socks.js" /* webpackChunkName: "component---src-pages-socks-js" */),
  "component---src-pages-sportswear-js": () => import("./../../../src/pages/sportswear.js" /* webpackChunkName: "component---src-pages-sportswear-js" */),
  "component---src-pages-suit-js": () => import("./../../../src/pages/suit.js" /* webpackChunkName: "component---src-pages-suit-js" */),
  "component---src-pages-sustainability-gots-x-foursource-js": () => import("./../../../src/pages/sustainability/gots-x-foursource.js" /* webpackChunkName: "component---src-pages-sustainability-gots-x-foursource-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-sustainability-oekotex-x-foursource-js": () => import("./../../../src/pages/sustainability/oekotex-x-foursource.js" /* webpackChunkName: "component---src-pages-sustainability-oekotex-x-foursource-js" */),
  "component---src-pages-sustainability-textileexchange-x-foursource-js": () => import("./../../../src/pages/sustainability/textileexchange-x-foursource.js" /* webpackChunkName: "component---src-pages-sustainability-textileexchange-x-foursource-js" */),
  "component---src-pages-sustainability-wrap-x-foursource-js": () => import("./../../../src/pages/sustainability/wrap-x-foursource.js" /* webpackChunkName: "component---src-pages-sustainability-wrap-x-foursource-js" */),
  "component---src-pages-sweatshirt-js": () => import("./../../../src/pages/sweatshirt.js" /* webpackChunkName: "component---src-pages-sweatshirt-js" */),
  "component---src-pages-swimwear-js": () => import("./../../../src/pages/swimwear.js" /* webpackChunkName: "component---src-pages-swimwear-js" */),
  "component---src-pages-t-shirts-js": () => import("./../../../src/pages/t-shirts.js" /* webpackChunkName: "component---src-pages-t-shirts-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-trendbook-ss-24-js": () => import("./../../../src/pages/trendbook-ss24.js" /* webpackChunkName: "component---src-pages-trendbook-ss-24-js" */),
  "component---src-pages-turkey-js": () => import("./../../../src/pages/turkey.js" /* webpackChunkName: "component---src-pages-turkey-js" */),
  "component---src-pages-turkish-government-support-js": () => import("./../../../src/pages/turkish-government-support.js" /* webpackChunkName: "component---src-pages-turkish-government-support-js" */),
  "component---src-pages-underwear-lingerie-js": () => import("./../../../src/pages/underwear-lingerie.js" /* webpackChunkName: "component---src-pages-underwear-lingerie-js" */),
  "component---src-pages-uniform-js": () => import("./../../../src/pages/uniform.js" /* webpackChunkName: "component---src-pages-uniform-js" */),
  "component---src-pages-unsupported-browser-js": () => import("./../../../src/pages/unsupported-browser.js" /* webpackChunkName: "component---src-pages-unsupported-browser-js" */),
  "component---src-pages-vietnam-js": () => import("./../../../src/pages/vietnam.js" /* webpackChunkName: "component---src-pages-vietnam-js" */),
  "component---src-pages-virtual-tradeshow-js": () => import("./../../../src/pages/virtual-tradeshow.js" /* webpackChunkName: "component---src-pages-virtual-tradeshow-js" */)
}

